<template>
  <div v-if="searchStats" :class="['searchStats', $style.container]">
    <div :class="$style.statsContainer">
      <button
        v-for="(stats, key) of percentageStats"
        :key="key"
        :class="['focus:ring-TEMP !cursor-default', $style.statBtn]"
        type="button"
        @click="() => {}"
      >
        <!-- @click="$emit('selected', { key, stats })" -->
        <div>
          <svg
            :class="$style.radialSvg"
            :data-percentage="stats.percentage"
            viewBox="0 0 80 80"
          >
            <circle
              :class="[$style.circle, $style.circleIncomplete]"
              cx="40"
              cy="40"
              r="35"
            ></circle>
            <circle
              :class="[$style.circle, $style.circleComplete]"
              cx="40"
              cy="40"
              r="35"
              :style="getCompleteCircleStyle(stats.percentage)"
            ></circle>
            <text
              :class="$style.percentageLabel"
              x="50%"
              y="59%"
              transform="matrix(0, 1, -1, 0, 80, 0)"
            >
              {{ stats.percentagePretty }}%
            </text>
          </svg>
        </div>
        <div :class="$style.label">
          {{ labels[key] }}
        </div>
      </button>
    </div>

    <div :class="[$style.statsContainer]">
      <div
        :class="$style.flatStat"
        v-for="(stats, key) of flatStats"
        :key="key"
      >
        <div
          :class="$style.flatStatCircle"
          v-html="labels[key].formatDisplayValue(stats)"
        ></div>
        <span :class="$style.flatStatText"> {{ labels[key].label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'lodash-es';
const getCompleteCircleStyle = (matchedPercentage) => {
  const radius = 35;
  const circumference = 2 * Math.PI * radius;
  const strokeDashOffset =
    circumference - (matchedPercentage * circumference) / 100;

  return {
    'stroke-dashoffset': strokeDashOffset,
  };
};
export default {
  props: {
    searchStats: {
      type: Object,
    },
  },
  created() {
    this.labels = {
      iswcStats: 'have multiple iswc codes',
      registrationStats: 'have duplicate registrations',
      writerStats: 'have conflicting writers',
      averageAgeOfWorks: {
        label: 'is the average age of works',
        formatDisplayValue: (value) =>
          `${value}<span style="font-size:1.5rem;">yrs</span>`,
      },
      avg_split: {
        label: 'is the average split per work',
        formatDisplayValue: (value) =>
          value === 'N/A'
            ? value
            : `${value}<span style="font-size:1.5rem;">%</span>`,
      },
      isrc_count: {
        label: 'recordings found for these works',
        formatDisplayValue: (value) =>
          value === 'N/A'
            ? value
            : `${new Intl.NumberFormat('en-US', {}).format(value)}`,
      },
    };
  },
  computed: {
    percentageStats() {
      return pick(this.searchStats, [
        'iswcStats',
        'registrationStats',
        'writerStats',
      ]);
    },
    flatStats() {
      return pick(this.searchStats, [
        'averageAgeOfWorks',
        'isrc_count',
        'avg_split',
      ]);
    },
  },
  methods: {
    getCompleteCircleStyle,
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
}
.statsContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-right: 4rem;
  justify-content: space-around;

  &:first-child {
    margin-bottom: 3rem;
  }
}

.flatStat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.flatStatText {
  font-weight: normal;
  font-size: 1.1rem;
  letter-spacing: 0px;
  color: #000;
}

.flatStatCircle {
  background-color: $color-primary;
  border-radius: 100%;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.25rem;
  color: white;
  font-weight: bold;
}
.statBtn {
  outline: none;
  border: none;
  background: transparent;
}
/* radial progress */
.radialSvg {
  height: auto;
  max-width: 300px;
  transform: rotate(-90deg);
  width: 150px;
  display: block;
  margin: 0 auto;
}

.radialSvg .circle {
  fill: rgba(0, 0, 0, 0);
  stroke: #dcc6f4;
  stroke-dashoffset: 219.91148575129;
  stroke-width: 6;
}

.radialSvg .circleIncomplete {
  stroke: #79e3c3;
}

.radialSvg .circleComplete {
  stroke-dasharray: 219.91148575129;
}

.radialSvg text {
  fill: #fff;
  text-anchor: middle;
  font-size: 1.3rem;
}

.percentageLabel {
  fill: #373a3c !important;
  font-weight: bold;
}

.percentageNum {
  font-size: 2rem;
}

.label {
  margin-top: 1rem;
  font-size: 1.1rem;
  font-family: $primary-font;

  @media screen and (max-width: 1028px) {
    max-width: 10rem;
  }
}
</style>

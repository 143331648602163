var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.appliedFilters.length && _vm.$style.hasFilters]},[_c('div',{class:_vm.$style.mapViewContent},[_c('ul',{class:_vm.$style.tracksList,style:({ visibility: _vm.selectedTrack ? 'hidden' : 'visible' })},[_vm._l((_vm.distinctTracks),function(track){return _c('li',{key:track.objectID},[_c('div',{class:_vm.$style.trackItem},[_c('IconCheckbox',{attrs:{"checked":track.objectID in _vm.selectedTracksForDownload},on:{"change":function($event){return _vm.onTrackSelectChange($event, track)}}}),_c('button',{class:_vm.$style.trackBtn,on:{"click":function($event){return _vm.$emit('trackSelected', track)}}},[_c('span',{class:_vm.$style.trackTitle,attrs:{"title":track.originalTitle}},[_vm._v(_vm._s(track.title))]),_c('span',{class:_vm.$style.trackIswc},[_vm._v(_vm._s(Array.isArray(track.registrations) ? track.registrations.length : 0)+" registrations")])])],1)])}),_c('li',[_c('LoadMore',{on:{"loadMore":function($event){!_vm.isLastPage && _vm.$emit('loadMoreTracks')}}})],1)],2),_c('SearchStats',{class:_vm.searchStatsClass,attrs:{"searchStats":_vm.searchStats},on:{"selected":function($event){return _vm.$emit('addFilter', $event)}}})],1),_c('footer',{class:_vm.$style.footer},[_c('div',{class:_vm.$style.selectedTracksActions},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.tracks && !_vm.selectedTrack && _vm.selectedTracksForDownloadCount === 0
        ),expression:"\n          tracks && !selectedTrack && selectedTracksForDownloadCount === 0\n        "}],class:_vm.$style.viewLink,attrs:{"to":{
          path: ("/" + (this.isDemo ? ("d/" + (this.$route.params.algolia_index)) : 'catalogs') + "/search/table"),
          query: this.$route.query,
          params: this.$route.params,
        }}},[_c('BaseIcon',{class:_vm.$style.viewLinkIcon,attrs:{"icon":"table"}}),_vm._v("Table View")],1),(_vm.selectedTracksForDownloadCount > 0 || _vm.selectedTrack)?_c('BaseButton',{class:[_vm.$style.viewLink, _vm.$style.downloadTracksBtn],attrs:{"disabled":_vm.downloadStatus.downloadSelectedTracksStatusPending},on:{"click":_vm.initDownloadTracks}},[_vm._v(" "+_vm._s(_vm.downloadStatus.downloadSelectedTracksStatusPending ? "Downloading tracks..." : _vm.selectedTrack ? "Download registrations" : ("Download Selected (" + _vm.selectedTracksForDownloadCount + ")"))+" ")]):_vm._e(),(_vm.allRegistrationsCount && !_vm.selectedTrack)?_c('BaseButton',{attrs:{"disabled":_vm.downloadStatus.downloadSelectedTracksStatusPending},on:{"click":function($event){return _vm.$emit('downloadAllRegistrations')}}},[_vm._v(" "+_vm._s(_vm.downloadStatus.downloadSelectedTracksStatusPending ? "Downloading tracks..." : ("Download All (" + _vm.allRegistrationsCount + ")"))+" ")]):_vm._e()],1),(_vm.selectedTrack)?_c('div',{class:_vm.$style.statusLegendContainer},[_c('StatusLegend',{attrs:{"foundStatusClasses":{
          circleStyle: _vm.$style.foundCircle,
          innerCircleStyle: _vm.$style.foundInnerCircle,
        }}})],1):_c('BaseButton',{class:_vm.$style.catalogMenu,attrs:{"variant":"icon"},on:{"click":function($event){$event.preventDefault();return _vm.onShowCatalogMenu.apply(null, arguments)}}},[_c('BaseIcon',{class:_vm.$style.catalogMenuIcon,attrs:{"icon":"user-cog","size":"1x"}})],1)],1),_c('CatalogMenuModal',{on:{"openSearchListsModal":function($event){return _vm.$emit('openSearchListsModal')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
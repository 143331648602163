<template>
  <vue-final-modal v-model="showModal" name="catalog-menu-modal">
    <div :class="$style.catalogMenuContainer">
      <div>
        <header></header>
        <main :class="$style.content">
          <ul :class="$style.menu">
            <li>
              <Logout
                :logoutLinkClass="$style.menuItemText"
                :afterLogoutUrl="afterLogoutUrl"
              />
            </li>
            <li>
              <router-link :class="$style.menuItemText" to="/catalogs">
                choose catalog
              </router-link>
            </li>
            <li>
              <button
                :class="[$style.menuItemText, $style.menuItemBtn]"
                @click="onSearchLists"
              >
                search lists
              </button>
            </li>
            <li>
              <BaseButton
                :class="$style.closeMenuModalButton"
                variant="icon"
                @click="showModal = false"
              >
                <BaseIcon :class="$style.menuItemText" icon="times" />
              </BaseButton>
            </li>
          </ul>
        </main>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import Logout from '@/components/menu/Logout';

export default {
  name: 'CatalogMenuModal',
  components: {
    Logout,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    afterLogoutUrl() {
      if (this.$route.path.includes('/d/')) {
        return `/d/${this.$route.params.algolia_index}`;
      }

      return '/login?redirect=/catalogs/search/map';
    },
  },
  methods: {
    onSearchLists() {
      this.$emit('openSearchListsModal');
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" module>
.catalogMenuContainer {
  background: #fff;
  min-height: 100vh;

  .content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .menuItemText {
    color: $color-grey-light;
    font-size: 3rem;
    text-align: center;
    font-weight: normal;
    text-transform: lowercase;
    display: block;
    margin: 0 auto;
    &:hover {
      color: $color-text;
    }
  }

  .menuItemBtn {
    border: none;
    outline: none;
    background: transparent;
    font-weight: 400;
    font-family: helveticaneue;
  }

  .closeMenuModalButton {
    display: block;
    margin: 0 auto;
  }
}
</style>

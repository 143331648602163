<template>
  <div :class="[appliedFilters.length && $style.hasFilters]">
    <div :class="$style.mapViewContent">
      <ul
        :class="$style.tracksList"
        :style="{ visibility: selectedTrack ? 'hidden' : 'visible' }"
      >
        <li v-for="track of distinctTracks" :key="track.objectID">
          <div :class="$style.trackItem">
            <IconCheckbox
              :checked="track.objectID in selectedTracksForDownload"
              @change="onTrackSelectChange($event, track)"
            />

            <button
              :class="$style.trackBtn"
              @click="$emit('trackSelected', track)"
            >
              <span :class="$style.trackTitle" :title="track.originalTitle">{{
                track.title
              }}</span>
              <span :class="$style.trackIswc"
                >{{
                  Array.isArray(track.registrations)
                    ? track.registrations.length
                    : 0
                }}
                registrations</span
              >
            </button>
          </div>
        </li>
        <li>
          <LoadMore @loadMore="!isLastPage && $emit('loadMoreTracks')" />
        </li>
      </ul>
      <SearchStats
        :searchStats="searchStats"
        :class="searchStatsClass"
        @selected="$emit('addFilter', $event)"
      />
    </div>
    <footer :class="$style.footer">
      <div :class="$style.selectedTracksActions">
        <router-link
          v-show="
            tracks && !selectedTrack && selectedTracksForDownloadCount === 0
          "
          :class="$style.viewLink"
          :to="{
            path: `/${
              this.isDemo ? `d/${this.$route.params.algolia_index}` : 'catalogs'
            }/search/table`,
            query: this.$route.query,
            params: this.$route.params,
          }"
          ><BaseIcon :class="$style.viewLinkIcon" icon="table" />Table
          View</router-link
        >
        <BaseButton
          v-if="selectedTracksForDownloadCount > 0 || selectedTrack"
          :class="[$style.viewLink, $style.downloadTracksBtn]"
          @click="initDownloadTracks"
          :disabled="downloadStatus.downloadSelectedTracksStatusPending"
        >
          {{
            downloadStatus.downloadSelectedTracksStatusPending
              ? `Downloading tracks...`
              : selectedTrack
              ? `Download registrations`
              : `Download Selected (${selectedTracksForDownloadCount})`
          }}
        </BaseButton>
        <BaseButton
          v-if="allRegistrationsCount && !selectedTrack"
          :disabled="downloadStatus.downloadSelectedTracksStatusPending"
          @click="$emit('downloadAllRegistrations')"
        >
          {{
            downloadStatus.downloadSelectedTracksStatusPending
              ? `Downloading tracks...`
              : `Download All (${allRegistrationsCount})`
          }}
        </BaseButton>
        <!-- <BaseButton
          v-if="selectedTracksForDownloadCount > 0 && !selectedTrack"
          :class="[$style.viewLink, $style.downloadTracksBtn]"
          @click="$emit('showTracksListModal')"
        >
          {{ `Save Selected (${selectedTracksForDownloadCount})` }}
        </BaseButton> -->
      </div>
      <div v-if="selectedTrack" :class="$style.statusLegendContainer">
        <StatusLegend
          :foundStatusClasses="{
            circleStyle: $style.foundCircle,
            innerCircleStyle: $style.foundInnerCircle,
          }"
        />
      </div>
      <BaseButton
        v-else
        :class="$style.catalogMenu"
        variant="icon"
        @click.prevent="onShowCatalogMenu"
      >
        <!-- <router-link to="/catalogs">CHOOSE CATALOG</router-link> -->
        <BaseIcon :class="$style.catalogMenuIcon" icon="user-cog" size="1x" />
      </BaseButton>
    </footer>
    <CatalogMenuModal @openSearchListsModal="$emit('openSearchListsModal')" />
  </div>
</template>
<script>
import StatusLegend from './components/StatusLegend';
import CatalogMenuModal from './components/CatalogMenuModal.vue';
import { LoadMore } from '@/components/common';
import IconCheckbox from './components/IconCheckbox';
import SearchStats from './components/SearchStats.vue';
import { search } from '@algolia/client-search';
export default {
  name: 'CatalogMapView',
  components: {
    StatusLegend,
    LoadMore,
    CatalogMenuModal,
    IconCheckbox,
    SearchStats,
  },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
    distinctTracks: {
      type: Array,
      default: () => [],
    },

    selectedTrack: {
      type: Object,
    },
    isLastPage: {
      type: Boolean,
      default: false,
    },
    selectedTracksForDownload: {
      type: Object,
      required: true,
    },
    addTrackForDownload: {
      type: Function,
      required: true,
    },
    removeTrackForDownload: {
      type: Function,
      required: true,
    },
    selectedTracksForDownloadCount: {
      type: Number,
      required: true,
    },
    downloadStatus: {
      type: Object,
      default: () => ({}),
    },
    allRegistrationsCount: {
      type: [String, Number],
      default: 0,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
    searchStats: {
      type: Object,
    },
    searchStatsClass: {
      type: [String, Array, Object],
      default: '',
    },
    appliedFilters: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onShowCatalogMenu() {
      this.$vfm.show('catalog-menu-modal');
    },
    onTrackSelectChange(checked, track) {
      checked
        ? this.addTrackForDownload(track, 'objectID')
        : this.removeTrackForDownload(track, 'objectID');
    },
    initDownloadTracks() {
      this.$emit(
        'downloadSelectedTracks',
        this.selectedTrack
          ? {
              [this.selectedTrack.objectID]: this.selectedTrack,
            }
          : undefined
      );
    },
  },
};
</script>
<style lang="scss" module>
.mapViewContent {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.tracksList {
  list-style: none;
  margin: 18px 0;
  padding: 0;
  position: relative;
  height: calc(100vh - 18rem);
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  li + li {
    margin-top: 1rem;
  }
}

.hasFilters {
  .tracksList {
    height: calc(100vh - 22rem);
  }
}

.trackBtn {
  padding: 0;
  border: none;
  background: transparent;
  text-align: left;
  cursor: pointer;
  line-height: 1.4;
}

.trackTitle,
.trackIswc {
  display: block;
  font-size: 14px;
  font-family: $primary-font;
  letter-spacing: 1.5px;
}

.trackTitle {
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  font-weight: 500;
}

.trackIswc {
  color: $color-grey-light;
}

.trackItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.viewLink {
  display: block;
  color: $color-grey-light;
  z-index: 10;

  &:hover {
    color: #333;
  }
}

.selectedTracksActions {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: fixed;
  bottom: 3rem;
  margin-top: 3rem;
  z-index: 10;
}

.downloadTracksBtn {
  padding: 0 !important;
}

.viewLinkIcon {
  margin-right: 0.5rem;
}

.footer {
  margin: 3rem 5rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.catalogMenu {
  position: fixed;
  bottom: 3rem;
  right: 5rem;
  font-weight: 500;
  font-size: 14px;
  z-index: 10;
  display: flex;
  justify-content: flex;
  align-items: center;
  cursor: pointer;
  color: $color-grey-light;

  &:hover {
    color: $color-text;
  }
}

.catalogMenuIcon {
  font-size: 1.5rem;
}

.statusLegendContainer {
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  z-index: 5;
}

.foundCircle {
  border: 1px solid #b5b9cc !important;
}

.foundInnerCircle {
  background-color: #b5b9cc !important;
}
</style>
